.award1 {
    width: 100%;
}
.award2{
    width: 100%;
    height: 262px;
}
.award_banner {
    background-image: url(../../../assets//award.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 98%;
    text-align: left;
    padding: 103px 0px;
    margin: 113px 13px;
}
