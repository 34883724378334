* {
    margin: 0;
    padding: 0;
}


/* Navbar Css Starts */

.otherHeader .navbar {
    background-color: #fff !important;
}

.navbar {
    background-color: #cdaa6800 !important;
}

.navbar-brand {
    text-align: left;
    width: 29%;
}

.navbar-brand img {
    width: 40%;
}

.nav-link {
    color: #511709;
    font-weight: 600;
    font-size: 18px;
    margin-right: 20px;
}

.nav-link:hover {
    color: #85553D;
    border-bottom: 2px solid #000;
    transition: all 0.1s ease;
}

.active {
    color: #85553D !important;
    border-bottom: 2px solid #000;
}

.dropdown .dropdown-menu {
    display: none;
}

.dropdown-menu a {
    color: #000;
    text-decoration: none;
}

.dropdown:hover>.dropdown-menu,
.dropend:hover>.dropdown-menu {
    display: block !important;
}

.dropend .dropdown-toggle {
    background-color: transparent;
    color: #85553D;
    border: none;
}

.dropend .dropdown-toggle:hover {
    background-color: #85553D;
    color: #fff;
}

.dropdown-item:active {
    background-color: transparent !important;
}

.dropdown-item:hover {
    background-color: transparent !important;
}

.dropdown-item:hover {
    color: #511709;
    font-weight: 600;
}

form a{
    text-decoration: none;
}

form .btnbtn {
    padding: 10px 10px !important;
}

form a small{
    color: #000;
}

.category{
    font-size: 20px;
    text-align: center;
    background-color: #85553d;
    padding: 10px;
    color: #fff;
}

/* 
.dropend:hover .dropdown-menu{
    position: absolute;
    top: 0;
    left: 100%;
    margin-left: 21px;
} */


/* Navbar Css Ends */


/* For Mobile screen */
@media (max-width: 768px) {
    .navbar-brand {
        width: 24%;
    }

    .navbar-brand img {
        width: 100%;
    }

    .dropend:hover>.dropdown-menu {
        position: absolute;
        top: 46px;
        left: 0;
    }

    .navbar-collapse form{
        display: none !important;
    }
}

/* For Screen Larger than mobile */
@media (min-width: 768px) and (max-width: 2560px) {
    .head_form{
        display: none !important;
    }
}

/* Media query ends */


.navbar-fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    /* background-color: blue !important; */
    transition: background-color 0.3s ease;
}

.navbar-scrolled {
    background-color: #fff !important;
}

.head_form .btnbtn {
    background-color: white;
    color: blue;
    border: 1px solid blue;
}

.head_form .btnbtn:hover {
    background-color: lightblue;
    color: darkblue;
}

/* end */

