* {
    margin: 0;
    padding: 0;
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600&family=Poppins:wght@400;500;600&display=swap');

body {
    font-family: 'Noto Sans', sans-serif;
}

img {
    width: 100%;
}

.main_head h2 {
    color: #85553D;
    text-transform: capitalize;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

.main_p p {
    color: #626262;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}

p {
    line-height: 26px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}

/* Banner Css Starts */

.banner {
    background-color: #CDAA6869;
    padding-top: 7.2rem;
}


.banner_head h1{
    font-family: 'Roboto', sans-serif;
    font-weight: 400;   
}


.banner_left {
    margin: 52px 60px;
    text-align: left;
}

.banner_left .lets {
    color: #85553D;
    background-color: #85553D4F;
    padding: 7px 14px;
    width: fit-content;
    border-left: 2px solid #85553D;
    border-radius: 5px;
}

.banner_left h1 {
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 40px;
}

.banner_left .banner_p p {
    color: #9D481D;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 18px;

}

.btnbtn {
    background-color: #85553D;
    padding: 10px 27px;
    border: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}

.play_icon {
    color: #85553D;
    font-size: 50px;
    margin-left: 25px;
}

.btnbtn:hover {
    background-color: #fff;
    color: #85553D;
}

.banner_btn p {
    margin: 12px;
    color: #000;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;

    font-weight: 600;
}

/* Banner Css Ends */

/* Doc About Css Starts */
.Button_healthy {
    text-align: center;
}

.doc_btn button:hover {
    background-color: #85553D;
}

.doc_about {
    text-align: left;
}

.lets {
    color: #D5A021;
    background-color: #D5A0214F;
    padding: 7px 14px;
    border-left: 2px solid #D5A021;
    border-radius: 5px;
    font-weight: 400;
    text-transform: capitalize;
    font-family: 'Roboto', sans-serif;
   
}

.doc_details {
    text-align: left;
}

.doc_details h1 {
    font-size: 39px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
}

.doc_details h3 {
    font-size: 27px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
}

.doc_edu p {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 7px !important;
    font-weight: 300;
    font-family: 'Roboto', sans-serif;
}

.doc_content p {
    font-size: 14px;
    color: #535353;
    /* font-weight: 600; */
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
}

.doc_btn button {
    background-color: #D5A021;
    color: #fff;
    padding: 7px 13px;
    border: none;
}

.btn:first-child:active {
    background-color: #D5A021 !important;
}

.Button_healthy1 {
    text-align: left;
}

.docright {
    text-align: left;
}

.docright h1 {
    font-weight: 600;
    color: #132725;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
}

.docright p {
    color: #797979;
    font-size: 15px;
    font-weight: 300;
    /* font-weight: 400; */
    font-family: 'Roboto', sans-serif;
}

.docsub span {
    color: #D5A021;
    font-weight: 600;
}


.docsub1 span {
    color: #D5A021;
    font-weight: 600;
    /* font-weight: 400; */
    font-family: 'Roboto', sans-serif;
}

.docsub1 p {
    color: #797979;
    font-size: 15px;
    font-weight: 300;
    font-family: 'Roboto', sans-serif;
}

.docparts {
    text-align: left;
}

.docpart1 {
    background-color: #B78530;
    padding: 30px;
    text-align: left;
    border-radius: 80px 14px 80px 17px;
}

.docpart1 p {
    color: #fff;
    text-align: center;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
}

.docsign {
    text-align: center;
}

.docsign img {
    width: 54%;
}

.doc_name p {
    font-weight: 600;
    margin-bottom: 0px;
    font-family: 'Roboto', sans-serif;
}

.doc_name small {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
}

.docpoints {
    margin: 70px 0px;
}

.docpoints ul {
    padding-left: 0px !important;
}

.docpoints li {
    list-style: none;
    font-size: 13.2px;
    padding: 5px 0px;
    color: #1C2F2D;
    font-weight: 600;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
}

.arrow_right {
    color: #D5A021;
}


.doc_detail_upper {
    border-bottom: 80px solid #D5A021;
}

.doc_details_head h1 {
    color: #D5A021;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 8.5rem;
}

.doc_head_brief {
    padding-bottom: 90px;
}

.doc_list_card {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
}

.doc_card_data {
    padding: 10px;
}

.doc_card_data span{
    font-size: 14px;
}

.doc1 {
    margin-top: -188px;
}

.doc2 {
    margin-top: -120px;
}

.doc3 {
    margin-top: -38px;
}

/* .men_desc .doc_details{
    text-align: right !important;
} */
/* Doc About CSS Ends */


/* Choose CSS starts */

.choose_card1 {
    background-image: linear-gradient(180deg, rgba(133, 85, 61, 0.28) 0%, rgba(217, 217, 217, 0) 100%);
    border-radius: 80px 20px 80px 20px;
    border: 1px solid rgba(133, 85, 61, 1);
    padding: 28px 30px;
    margin: 0px 10px;
}

.choose_card2 {
    background-image: linear-gradient(180deg, rgba(213, 160, 33, 0.35) 0%, rgba(217, 217, 217, 0) 100%);
    border-radius: 80px 80px 20px 15px;
    border: 1px solid rgba(133, 85, 61, 1);
    padding: 28px 30px;
    margin: 0px 10px;
}

.choose_card3 {
    background-image: linear-gradient(180deg, rgba(133, 85, 61, 0.28) 0%, rgba(217, 217, 217, 0) 100%);
    border-radius: 20px 80px 20px 80px;
    border: 1px solid rgba(133, 85, 61, 1);
    padding: 28px 30px;
    margin: 0px 10px;
}

.choose_card4 {
    background-image: linear-gradient(180deg, rgba(133, 85, 61, 0.28) 0%, rgba(217, 217, 217, 0) 100%);
    border-radius: 20px 30px 20px 80px;
    border: 1px solid rgba(133, 85, 61, 1);
    padding: 28px 30px;
    margin: 0px 10px;
}

.choose_card5 {
    background-image: linear-gradient(180deg, rgba(213, 160, 33, 0.35) 0%, rgba(217, 217, 217, 0) 100%);
    border-radius: 20px 20px 80px 80px;
    border: 1px solid rgba(133, 85, 61, 1);
    padding: 28px 30px;
    margin: 0px 10px;
}

.choose_card6 {
    background-image: linear-gradient(180deg, rgba(133, 85, 61, 0.28) 0%, rgba(217, 217, 217, 0) 100%);
    border-radius: 20px 30px 80px 20px;
    border: 1px solid rgba(133, 85, 61, 1);
    padding: 28px 30px;
    margin: 0px 10px;
}

.choose_icon img {
    width: 46%;
}

.choose_head h6 {
    color: #85553D;
    font-weight: 600;
    /* font-weight: 400; */
    font-family: 'Roboto', sans-serif;
}

.choose_p p {
    color: #595959;
    font-size: 15px;
    font-weight: 300;
    font-family: 'Roboto', sans-serif;
}

/* Choose CSS Ends */

/* Steps Css Starts */

.steps {
    position: relative;
}

.step_count p {
    color: rgba(0, 0, 0, 0.27);
    font-weight: 400;
    margin-bottom: 7px;
    font-family: 'Roboto', sans-serif;
}

.step_icon img {
    width: 15%;
}

.step_head h6 {
    color: #85553D;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
}

.step_content p {
    color: #595959;
    font-size: 15px;
    font-weight: 300;
    font-family: 'Roboto', sans-serif;
}


/* Steps Css Ends */

/* Make Appointment Css Starts */

.appoint {
    background-color: rgba(183, 133, 48, 1);
    text-align: left;
}


.appoint_left .lets {
    color: #FFFFFF;
    background-color: #FFFFFF5C;
    padding: 7px 14px;
    width: fit-content;
    border-left: 2px solid #FFFFFF;
    border-radius: 5px;
    font-weight: 300;
}

.appoint_left h2 {
    color: #FFFFFF;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

.check_icon {
    font-size: 20px;
    color: #fff;
    width: 100%;
    height: 29%;
}

.app_head h5 {
    color: #FFFFFF;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

.app_p p {
    font-size: 15px;
    color: #FFFFFF;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

.appoint_card {
    background-color: #FFFFFF;
    padding: 20px 20px;
    border-radius: 10px;
    margin-right: 100px;
}

.appoint_card select {
    border: 1px solid #8F8F8F;
}

.appoint_card input {
    border: 1px solid #8F8F8F;
    border-radius: 3px;
}

.appoint_card textarea {
    border: 1px solid #8F8F8F;
    border-radius: 3px;
    width: 100%;
    padding: 8px;
}

.appoint_card input::placeholder {
    color: #616161;
    font-size: 15px;
}

.appoint_btn {
    text-align: center;
}

.appoint_btn button {
    background-color: #85553D;
    color: #fff;
    border: none;
    padding: 6px 55px;
}

.appoint_btn button:hover {
    background-color: #D5A021;
}

.btn:focus-visible {
    background-color: #D5A021;
    border-color: #d59f2160;
}

/* Make Appointment Css Ends */

/* Testimonial CSS Starts */

.client_icon img {
    width: 5%;
}

.slick-slide img {
    display: initial !important;
}

.client_name {
    color: #85553D;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
}

.client_place {
    font-size: 15px;
    color: #626262;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
}

.slick-dots li button:before {
    font-size: 15px !important;
    color: #d59f21 !important;
    box-shadow: 0 6px 1px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 50%;
}

.client_says p {
    color: #1E1919;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
}

.slick-dots li.slick-active button:before {
    color: #D5A021 !important;
}

.sharva_banner img{
    margin-top: 40px;
}

.docimg img{
    margin-top: 144px;
}

.banner_img img{
    width: 90%;
}

.client_says .customer_review{
    width: 50% !important;
}

/* Testimonial CSS Ends */


/* Media query starts */

/* For Mobile screen */
@media (max-width: 768px) {

    .banner_left {
        margin: 94px 0px;
    }

    .banner_left .lets {
        width: fit-content;
    }

    .appoint_card {
        margin-right: 0px;
    }

    .slick-next {
        right: 0px !important;
    }

    .slick-prev {
        left: 0px !important;
    }

    .doc_head_brief {
        padding-bottom: 10px;
    }

    .doc1 {
        margin-top: 20px;
    }

    .doc2 {
        margin-top: 20px;
    }

    .doc3 {
        margin-top: 20px;
    }

    .doc_detail_upper {
        border-bottom: none;
    }

    .doc_details_head h1 {
        margin-bottom: 0.5rem;
    }

    .doc_btn{
        padding-bottom: 1.5rem !important;
        text-align: center !important;
    }

    .docpoints{
        margin: 0px 0px !important;
    }

    .gallery_img img{
        margin-top: 20px !important;
    }

    .docimg img{
        margin-top: 0px;
    }
}

/* For Screen Larger than mobile */
@media (min-width: 768px) and (max-width: 2560px) {}

/* Media query ends */