.service_banner {
    background-image: url(../../assets/service_banner.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 98%;
    text-align: left;
    padding: 90px 0px;
    margin: 0px 13px;
}

.service_head span{
    color: #D5A021;
}

.service_content{
    text-align: left;
}

.service_desc h5{
    color: #D5A021;
}

/* For Screen Larger than mobile */
@media (min-width: 1400px) {
    .service_banner .container {
        max-width: 1140px !important;
    }
}