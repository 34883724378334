.footer{
    text-align: left;
}

.logo img{
    width: 30%;
}

.about p{
    font-size: 14px;
    font-weight: 600;
}

.social_icon {
    font-size: 34px;
    margin-right: 27px;
    border: 1px solid #000;
    padding: 7px;
    border-radius: 16px 6px 6px 2px;
    cursor: pointer;
}

.social_links a{
    color: #000;
}

.social_icon:hover{
    background-color: #D5A021;
    color: #fff;
    border: 1px solid #fff;
    transition: all 0.3s ease;
}

.quick_link{
    margin: 32px 0px 0px 15px;
}

.quick_link h5{
    color: #85553D;
}

.quick_link ul{
    padding-left: 0px;
}

.quick_link a{
    text-decoration: none;
}

.quick_link li:hover{
    color: #D5A021;
    transition: all 0.3s ease;
}

.quick_link li{
    list-style: none;
    padding-top: 13px;
    font-size: 14px;
    font-weight: 600;
    color: #737373;
}

.soc_icon{
    color: #D5A021;
    font-size: 23px;
    margin-top: 16px;
}
/* For Mobile screen */
@media (max-width: 768px) {

    .quick_link{
        margin: 32px 0px 0px 10px;
    }

}

/* For Screen Larger than mobile */
@media (min-width: 768px) and (max-width: 2560px) {}

/* Media query ends */