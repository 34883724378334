.contact_banner {
    background-image: url(../../assets/contact_banner.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 98%;
    text-align: left;
    padding: 103px 0px;
    margin: 113px 13px;
}


.contact_p {
    margin-bottom: 3rem;
}

.contact_left_card {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px 70px 10px 70px;
    padding: 7px 35px;
}

.contact_right_card {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 70px 10px 70px 10px;
    padding: 15px 35px 7px 35px;
}

.contact_text h5 {
    font-size: 19px;
}

.contact_text p {
    margin-bottom: 0px !important;
    font-size: 15px;
    color: #656565;
}

.contact_data {
    text-align: left;
}

.contact_icon img {
    width: 74%;
}

.contact_page_form button {
    width: 100%;
}

.contact_page_form .form-control {
    border: 1px solid #000 !important;
    border-radius: 28px;
    padding: 10px 20px;
}

.contact_page_form {
    padding: 30px 66px;
}

.contact_page_form .form-control:focus {
    box-shadow: 0 0 0 0.25rem #d59f218b !important;
    border: 1px solid #d59f218b !important;
}


/* Media query starts */

/* For Mobile screen */
@media (max-width: 768px) {
    .gallery_head {
        display: none;
    }

    .Button_healthy1{
        padding-top: 0px !important;
    }
    
    .contact_page_form {
        padding: 60px 66px;
    }
}

/* For Screen Larger than mobile */
@media (min-width: 1400px) {
    .contact_banner .container {
        max-width: 1140px !important;
    }
}


/* Media query ends */