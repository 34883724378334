.doctor_banner {
    background-image: url(../../assets/doctor.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 98%;
    text-align: left;
    padding: 103px 0px;
    margin: 0px 13px;
}

.doctor_banner .gallery_head h1 {
    font-size: 45px;
}

.doctor_banner .gallery_head p {
    font-size: 17px;
}

.doc_edu p{
    font-size: 16px !important;
}

/* Media query starts */

/* For Mobile screen */
@media (max-width: 768px) {
    .gallery_head {
        display: none;
    }
}

/* For Screen Larger than mobile */
@media (min-width: 1400px) {
    .doctor_banner .container {
        max-width: 1140px !important;
    }
}

/* Media query ends */