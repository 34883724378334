.whatsapp_float{
    position: fixed;
    bottom: 40px;
    right: 20px;
}

.whatsapp_float img{
    width: 50px;
}

