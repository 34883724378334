.gallery_banner {
    background-image: url(../../assets/gallery_banner.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 98%;
    text-align: left;
    padding: 103px 0px;
    margin: 113px 13px;
}

.gallery_head h1 {
    font-size: 45px;
}

.gallery_head p {
    font-size: 17px;
}

.react-tabs__tab-list{
    border-bottom: 2px solid #85553D !important;
}

.react-tabs__tab {
    display: inline-block;
    border-bottom: 1px solid #aaa !important;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    background-color: #DCAF44;
    color: #fff !important;
    border-radius: 5px;
    margin: 0px 3px;
}


.react-tabs__tab--selected {
    background: #fff;
    border-color: #DCAF44 !important;
    border-bottom: 1px solid #D5A021 !important;
    color: black;
    border-radius: 5px 5px 0 0;
    background-color: #D5A021 !important;
}


.sub_photos .media-container{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 15px;
    margin: 20px auto;
}


.sub_photos .media{
    height: 220px;
    width: 100%;
    border: 5px solid #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.7);
    overflow: hidden;
    cursor: pointer;
}

.sub_photos .media img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: all 0.3s linear;
}

.sub_photos .media:hover img{
    transform: scale(1.1);
}

.popup-media{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
}

.popup-media img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    max-width: 95%;
    max-height: 95%;
    object-fit: contain;
}

.popup-media span{
    position: absolute;
    top: 5px;
    right: 20px;
    font-size: 50px;
    font-weight: bolder;
    z-index: 100;
    cursor: pointer;
    color: #fff;
    user-select: none;
}

.improve_images{
    padding: 16px;
}

.improve_images img{
    height: 50vh;
}

/* Media query starts */

/* For Mobile screen */
@media (max-width: 768px) {
    .gallery_head {
        display: none;
    }

    .gallery_tabs p{
        padding-top: 0px !important;
    }
}

/* For Screen Larger than mobile */
@media (min-width: 1400px) {
    .gallery_banner .container {
        max-width: 1140px !important;
    }
}

/* Media query ends */