.privacy_banner {
    background-image: url(../../assets/privacy_policy.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 98%;
    text-align: left;
    padding: 103px 0px;
    margin: 0px 13px;
}

.privacy_details{
    text-align: left;
}

.privacy_details h3{
    margin: 20px 0px;
}