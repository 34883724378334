/* About Banner CSS Starts */
.banner_left1 {
    margin: 47px 13px;
    text-align: left;
}

.aboutbanner{
    margin-top: 113px;
}

.banner_left1 .lets {
    color: #D5A021;
    background-color: #D5A0214F;
    padding: 7px 14px;
    border-left: 2px solid #D5A021;
    border-radius: 5px;
    text-transform: capitalize;
    width: fit-content;
}

.lets .lets_icon {
    margin-top: -1px;
}

/* About Banner Css Ends */
/* Doc about Css Starts */

.doctor_about {
    text-align: left;
}

.doctor_about .Button_healthy {
    text-align: left;
}

.doc_card {
    border: 1px solid rgba(133, 85, 61, 1);
    padding: 10px;
    border-radius: 10px;
    margin-top: 7rem;
}

.doc_card img {
    margin-top: 12px;
}

.doc_head span {
    color: rgba(133, 85, 61, 1);
}

.speciality {
    color: #D5A021;
}

.doc_card_left .abt_p {
    font-size: 15px;
    margin-bottom: 10px !important;
    color: #707070;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

.doc_social_icon {
    font-size: 30px;
    margin-right: 14px;
    border: 1px solid #000;
    padding: 7px;
    border-radius: 16px 6px 6px 2px;
    cursor: pointer;
}

.doc_social_icon:hover {
    background-color: #D5A021;
    color: #fff;
    border: 1px solid #fff;
    transition: all 0.3s ease;
}


/* Doc about Css Ends */

/* Doc about desc CSS Starts */

.aboutDesc {
    text-align: left;
}

.aboutdesc_left h2 {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

.aboutdesc_left p {
    color: #717171;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

.about_desc_img img {
    margin: 38px 0px;
}

.count_card1 {
    background-color: #D5A021;
    color: #fff;
    padding: 17px 0px;
    text-align: center;
    margin-top: 10px;
    border: none;
    border-radius: 87px 44px 14px 5px;
    width: 70%;
}

.count_card2 {
    background-color: #D5A021;
    color: #fff;
    padding: 17px 0px;
    text-align: center;
    margin-top: 10px;
    border: none;
    border-radius: 40px 33px 77px 5px;
    width: 70%;
}

.count_card3 {
    background-color: #D5A021;
    color: #fff;
    padding: 17px 0px;
    text-align: center;
    margin-top: 10px;
    border: none;
    border-radius: 40px 33px 77px 5px;
    width: 70%;
}

.count_card4 {
    background-color: #D5A021;
    color: #fff;
    padding: 17px 0px;
    text-align: center;
    margin-top: 10px;
    border: none;
    border-radius: 87px 44px 14px 5px;
    width: 70%;
}

.abt_count p{
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

/* Doc about desc CSS Ends */


/* Media query starts */

/* For Mobile screen */
@media (max-width: 768px) {
    .doc_card img {
        margin-top: 10px;
    }

    .aboutdesc_left h2{
        margin-top: 0px;
    }
}

/* For Screen Larger than mobile */
@media (min-width: 768px) and (max-width: 2560px) {}

/* Media query ends */